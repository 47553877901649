import React from 'react';


const Button = (props) => {
    return (
        <button
            onClick={props.func}
            style={props.style != undefined ? props.style : {
                background: "linear-gradient(to left, #fef6a1,  #93630f)",
                // boxShadow: `3px 2px 2px ${outbuttonsColor}`, 
                border: "none",
                cursor: "pointer",
                borderRadius: "90px",
                minWidth: "100px",
                padding: "5px 10px", height: "100%", maxHeight: "60px", minHeight:"35px",
                color: "#fff",
                fontSize: "1.6em", width:
                    "100%", margin: "auto",
            }}>
            <p >{props.text}</p>
        </button>
    );
}

export default Button;