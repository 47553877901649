import React, { Component, useEffect, useState } from 'react';

import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { AnimatedCounter } from 'react-animated-counter';
import { loadReward } from '../../../hooks/tools';
import { ethers } from 'ethers';
const NumberBig = ({ value , color, Tokencontract, address}) => {
    const [reward, setReward] = useState(0);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            loadReward(Tokencontract, "0x75E88d3e0D8FCa5C0f89026ECbDF0D8867470ade", address,setReward)
          //  loadPool(Tokencontract, "0x75E88d3e0D8FCa5C0f89026ECbDF0D8867470ade", address, setModal)
        }, 3000);
        return () => clearInterval(interval);
    }, [seconds]);
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length > d) {
                const value = parseFloat(
                    ethers.utils.formatUnits(accountBalancemctTB, d)
                ).toFixed(2);

                const decimals = value.toString().split(".")[1];
                // const plus =
                return parseFloat(
                    parseInt(decimals) > 0
                        ? parseFloat(value)
                        : parseFloat(value).toFixed(0)
                );
            } else {
                let cutin = d - accountBalancemctTB.toString().length + 2;
                return parseFloat(
                    ethers.utils.formatUnits(accountBalancemctTB, d)
                ).toFixed(cutin);
            }
        } else {
            return 0.0;
        }
    }
    return (
        <AnimatedCounter value={nextblock(reward, 18)} color={color} fontSize={`${(window.innerWidth / 21) > 42 ? 42 : window.innerWidth / 21}px`}  containerStyles={{gap:"3px"}} decimalPrecision={18}/>
    );
}
export default NumberBig;
