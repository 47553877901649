import React, { useContext } from 'react';
import AppContext from '../../framework/helpers/AppContext';


function InputBlock(props) {
  const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers
  } = useContext(AppContext).theme
  return (
    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "5px", margin: "auto" }}>
      {props.title != undefined && <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: outFont, fontSize: "14px" }}>{props.title}</p>}
      {
        props.variavel == undefined
          ? <input
            id={props.id == undefined ? "" : props.id}
            type={props.type == undefined ? "text" : props.type}
            onChange={(e) => { props.setvariavel(e.target.value) }}
            placeholder={props.place}
            style={{ fontSize: "15px", outline: "none", color: innerFont, textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: inputsBorders, background: inputsColor, borderRadius: "90px", paddingLeft: "10px" }}></input>
          : <input
            id={props.id == undefined ? "" : props.id}
            type={props.type == undefined ? "text" : props.type}
            onChange={(e) => { props.setvariavel(e.target.value) }}
            placeholder={props.place}
            value={props.variavel}
            style={{ fontSize: "15px", outline: "none", color: innerFont, textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: inputsBorders, background: inputsColor, borderRadius: "90px", paddingLeft: "10px" }}></input>
      }

      <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: outFont, fontSize: "10px" }}>{props.desc}</p>
    </div>
  );
}

export default InputBlock;