import abiToken from "../abis/bnbabi.json";
import stakesabi from "../abis/stakesn.json";
import stkfacabi from "../abis/stakefactory.json";
import { BigNumber } from "ethers";
export async function loadPool(Tokencontract, poolAddress, address, setModal) {
    try {//0x0427d0497fd82507666c186B50188526e02F2701

        // const stakefactory = await Tokencontract(stkfacabi, screens["stakepools"].factory)
        const poofee = 100000000000000//await stakefactory.methods.pfee().call()

        const stake = await Tokencontract(stakesabi, poolAddress)

        // const finishAt = await stake['methods']['finishAt']().call()


        const token0 = await stake['methods']['tokenNative']().call()
        const token1 = await stake['methods']['BEP20Rtoken']().call()

        const tokenA = await Tokencontract(abiToken, token0);
        const tokenB = await Tokencontract(abiToken, token1);

        const stakeData = address != undefined ? await stake.methods.userInfo(address).call() : {
            amount: 0
        }

        const estimate = BigNumber.from(await stake['methods']['_totalinreward']().call())

        const ts = BigNumber.from(await stake.methods._totalstakedIR().call())
        const staked = BigNumber.from(address != undefined ? stakeData.amount : 0)
        const claimable = BigNumber.from(address != undefined ? await stake['methods']['claimable'](address).call() : 0)
        //   console.log(staked.mul(BigNumber.from(100)).div(ts).toBigInt())
        const t = estimate.mul(staked.mul(BigNumber.from(100)).div(ts)).div(BigNumber.from(100)).add(claimable)

        const obj = {
            title: '',
            cts: poolAddress,
            apr: await stake.methods._FLEXAPR().call(),
            ts: ts,
            min: 10,
            ia: "i0",
            ib: "ib",
            act: token0,
            bct: token1,
            adec: await tokenA['methods']['decimals']().call(),
            bdec: await tokenB['methods']['decimals']().call(),
            taName: await tokenA['methods']['symbol']().call(),
            tbName: await tokenB['methods']['symbol']().call(),
            abalance: address != undefined ? await tokenA['methods']['balanceOf'](address).call() : 0,
            bbalance: address != undefined ? await tokenB['methods']['balanceOf'](address).call() : 0,
            aAllowance: address != undefined ? await tokenA['methods']['allowance'](address, poolAddress).call() : 0,
            bAllowance: address != undefined ? await tokenB['methods']['allowance'](address, poolAddress).call() : 0,
            stakelimit: address != undefined ? await stake['methods']['getDepositLimit'](address).call() : 0,
            so: address != undefined ? stakeData.amount > 0 : false,
            staked: address != undefined ? stakeData.amount : 0,
            claimable: address != undefined ? await stake['methods']['rewardinstake'](address).call() : 0,
            rewardRate: await stake['methods']['_rewardRate']().call(),
            estimate: t,//estimate.mul(staked).add(claimable).toBigInt(),
            desc: "",
            apydesc: "",
            poofee: poofee,
            official: 1,
            online: true
        }
        setModal(obj)
    } catch (error) {
        console.log(error)
        setModal(undefined)
    }
}
export async function loadReward(Tokencontract, poolAddress, address, setReward) {
    try {//0x0427d0497fd82507666c186B50188526e02F2701

        const stake = await Tokencontract(stakesabi, poolAddress)

        setReward(address != undefined ? await stake['methods']['rewardinstake'](address).call() : 0)
    } catch (error) {
        setReward(undefined)
    }
}