import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { bsc, bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  braveWallet,
  coinbaseWallet,
  metaMaskWallet,
  trustWallet
} from '@rainbow-me/rainbowkit/wallets';
import Interact from '../web3/interact';



export function Connection({ content, theme }) {
  
  const { chains, publicClient } = configureChains(
    [bsc, bscTestnet],
    [
      publicProvider()
    ]
  );

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        trustWallet({
          projectId: '3f529f8b097214c03d7190df245218c9',
          chains
        }),
        metaMaskWallet({
          projectId: '3f529f8b097214c03d7190df245218c9',
          chains
        }),
        injectedWallet({ chains }),
        rainbowWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
        walletConnectWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
        braveWallet({
          chains
        }),
        coinbaseWallet({
          appName: "ETHPERMINUTE",
          chains
        })
      ],
    },
  ]);
 
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })


  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Interact content={content} theme={theme} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
export default Connection;