import React, { useState, useEffect } from 'react';

import ProjectData from '../config';
let Name = "ETHPERMINUTE"

//
const Terms = () => {
  useEffect(() => {
    //document.title = "ETHPERMINUTE | Terms & Privacy"
  }, []);



  const Datablockjt = (props) => {
    return (
      <div style={{
        height: "min-content",

        width: "100%",
        display: "grid",
        gridTemplateRows: "auto",
        borderRadius: "2px",
        margin: "auto",
        marginTop: "0vh",

        marginBottom: "0vh",
        color: "#fff"
      }}>

        <div style={{
          height: "100%",
          width: "100%",
          display: "grid",
        }}>
          <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            marginTop: "0vh",
            marginBottom: "0vh",
            display: "grid",
          }}>

            <p style={{
              textAlign: "justify",
              fontSize: "3vh",
            }}><br /><span style={{
              textAlign: "center",
              fontSize: "5vh",
              fontWeight: "bold",
            }}>{props.title}
                <br /></span>
              <br />
              {props.text}
            </p>
          </div>

        </div>
      </div>
    );
  }
  const Datablockjtp = (props) => {
    return (
      <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        gridTemplateRows: "auto",
        borderRadius: "2px",
        margin: "auto",
        marginTop: "0vh",
        marginBottom: "0vh",
        color: "#fff"
      }}>

        <div style={{
          height: "100%",
          width: "100%",
          display: "grid",
        }}>
          <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            marginTop: "0vh",
            marginBottom: "0vh",
            display: "grid",
          }}>

            <p style={{
              textAlign: "justify",
              fontSize: "3vh",
            }}><span style={{
              textAlign: "left",
              fontSize: "3vh",
              fontWeight: "bold",
            }}>{props.title}</span>{props.text}
            </p>
            <br />
          </div>

        </div>
      </div>
    );
  }
  let titlessss = <div style={{
    height: "min-content",
    minHeight: "90vh",
    width: "100%",
    display: "grid",
    background: "#ffffff00",

    borderRadius: "2px",
    margin: "auto",

    color: "#fff"
  }}>

    <div style={{
      height: "100%",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(40vh, 1fr)",

      gap: "100px",
    }}>

      <div style={{
        height: "min-content",
        width: "100%",
        padding: "2vh",
        display: "grid",
        margin: "auto"
      }}>

        <p style={{
          textAlign: "left",
          fontSize: "100%",
          margin: "auto"
        }}>{Name}<br /><span style={{
          textAlign: "center",
          fontSize: "300%",
          fontWeight: "bold"
        }}>Terms & Privacy
            <br /></span>
          <br />

        </p>
        <Datablockjt title={"PRIVACY POLICY"} text={""} />
        <Datablockjtp title={""} text={"Your privacy is important to us. It is " + Name + "'s policy to respect your privacy regarding any information we may collect from you across our website, " + Name + ", and other sites we own and operate."} />
        <Datablockjtp title={""} text={"We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used."} />
        <Datablockjtp title={""} text={"We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification."} />
        <Datablockjtp title={""} text={"We don’t share any personally identifying information publicly or with third-parties, except when required to by law."} />
        <Datablockjtp title={""} text={"Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies."} />
        <Datablockjtp title={""} text={"You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services."} />
        <Datablockjtp title={""} text={"Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us."} />
        <Datablockjt title={"TERMS"} text={""} />
        <Datablockjtp title={""} text={"This document is not investment advice, do not invest anything that is not be willing to risk losing, be wary and wary of any individual or organization that promises you any guarantee of profit, every investment has risks and it is your responsibility to manage them if you do not have a broad knowledge of investing in crypto assets it is recommended to look for a qualified professional."} />
        <Datablockjtp title={""} text={"In blockchain technology there may be risks inherent to the structure itself. After all, it is an innovation with an abrupt and growing expansion, endowed with unprecedented reach. Therefore, it is essential to carefully analyze the risks associated with any transaction based on blockchain technology, such as acquiring crypto assets seeking help and advice from professionals specialists including advisors in the financial, (accounting, regulatory, tax, technology and other experts) before deciding to obtain a token."} />
        <Datablockjtp title={""} text={"When purchasing, owning, using and transacting with " + Name + ", you will be subject to risks, which you must assume in full, exempting the ETHPERMINUTEeloper and its partners, administrators, employees and representatives of any responsibility about losses and damages that may arise from them"} />
        <Datablockjtp title={""} text={"" + Name + " holders and users of the platform declare and guarantee that they assume the sole responsibility for any restrictions and risks associated with the possession or use of " + Name + "."} />
        <Datablockjt title={"PRIVACY POLICY UPDATES"} text={""} />
        <Datablockjtp title={""} text={"The " + Name + " project team reserves the right to change this Policy when necessary, to provide you with more security, convenience, and increasingly improve the project without regressing your rights. That's why it's It is very important to access our Policy periodically. in case they are made changes will be posted on our communication channels for the knowledge of the community at large."} />
      </div>


    </div>
  </div>

  return (
    <div className="Whitepaper">
      {titlessss}
    </div>
  );
}

export default Terms;