import React, { useContext } from "react"
import { ConnectButton } from '@rainbow-me/rainbowkit';
import ProjectData from '../../config';
import AppContext from "../../framework/helpers/AppContext";

const WButton = () => {
    const {

        backgroundColor,
        buttonsColor,
        buttonsBorders,
       
        outFont,
        topFont,
    
      } = useContext(AppContext).theme
    return (
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            const ready = mounted && authenticationStatus !== 'loading';
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === 'authenticated');
    
            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  'style': {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <div style={{ display: 'flex', width: "90px", background: buttonsColor, borderRadius: "10px", border: `1px solid #dcb100` }}>
               
                        <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openConnectModal}>
    
    
                          <h1>Connect Wallet</h1>
                        </div>
                      </div>
                    );
                  }
    
                  if (chain.unsupported) {
                    return (
                      <div style={{ display: 'flex', width: "90px", background: buttonsColor, borderRadius: "10px", border: `1px solid #dcb100` }}>
                   
                        <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openChainModal}>
    
                          <h1>Wrong network</h1>
                        </div>
                      </div>
                    );
                  }
    
                  return (
                    <div style={{ display: 'flex', width: "150px" }}>
                      <div style={{ display: 'flex', width: "120px", background: buttonsColor, borderRadius: "10px", border: `1px solid #dcb100` }}>
                        <div onClick={openChainModal} style={{ cursor: "pointer", borderRadius: "5px ", padding: "7px", margin: "auto", background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont, display: "grid" }}>
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ borderRadius: "5px ", width: "20px", height: "20px", margin: "auto" }}></img>
                        </div>
    
                        <div id="wallet" className="Wallet" style={{ background: "#00000000", border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openAccountModal}>
    
                          <h1>
                            {account.displayName}<p />
                            {account.displayBalance
                              ? ``
                              : ''}</h1>
                        </div>
                      </div>
                      {/*<Face skin={`rgb(255, 255, 255)`} details={`rgb(0, 0, 0)`} size={2} />*/}
                    </div>
    
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      );
}
export default WButton