import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import tokenabi from "../../abis/bnbabi.json";
import stakesabi from "../../abis/stakesn.json";
import AppContext from "../../framework/helpers/AppContext";

import ModalStake from "./components/modalStake";
import ModalRemove from "./components/modalRemoveStake";
import ModalCalc from "./components/modalCalc";
import ProjectData from '../../config';
import Button from "../../components/Launchpad/Button";
import Timer from "../../components/Timer/timer";
import { loadPool } from "../../hooks/tools";
import InputBlock from "../../components/Launchpad/InputBlock";
import Checkbox from "../../components/Launchpad/Checkbox";
import { MSS } from "../../hooks/style";
import NumberBig from "./components/number";



const NModal = ({ contract, data }) => {

    const ss = {
        "graphics": { background: "url('" + (window.location.protocol + "/border.png") + " ') center center / 100% 100%  no-repeat", display: "grid", gap: "10px", gridTemplateColumns: "100%", padding: "20px 5px", height: "100%", width: "100%", margin: "auto", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px", minHeight: "340px" },
        "positions": { display: "grid", gap: "10px", gridTemplateColumns: "100%", padding: "20px 5px", height: "min-content", width: "100%", margin: "auto", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" },
        "earnings": { display: "grid", gap: "10px", gridTemplateColumns: "100%", padding: "20px 5px", height: "100%", width: "100%", margin: "auto", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" },
        "blocks": { display: "grid", gap: "10px", gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)", padding: "20px 5px", height: "100%", minHeight: "100px", width: "100%", margin: "auto", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" }
    }
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT,
        theme
    } = useContext(AppContext)
    const {
        Name,
        Logo,
        backgroundColor,
        buttonsColor,
        buttonsBorders,
        inputsColor,
        inputsBorders,
        modalsColor,
        outbuttonsColor,
        Borders,
        outFont,
        innerFont,
        innerBFont,
        Routers
    } = theme
    const [view_Add, setview_Add] = useState(false);
    const [view_Roi, setview_Roi] = useState(false);

    // const [amusd, setAmusd] = useState(0);
    useEffect(() => {

    }, [])


    function call(func, callback, ...args) {
        func(...args).call()
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                //console.log(error)
            })
    }
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)

    }

    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length > d) {
                const value = parseFloat(
                    ethers.utils.formatUnits(accountBalancemctTB, d)
                ).toFixed(2);

                const decimals = value.toString().split(".")[1];
                // const plus =
                return parseFloat(
                    parseInt(decimals) > 0
                        ? parseFloat(value)
                        : parseFloat(value).toFixed(0)
                );
            } else {
                let cutin = d - accountBalancemctTB.toString().length + 2;
                return parseFloat(
                    ethers.utils.formatUnits(accountBalancemctTB, d)
                ).toFixed(cutin);
            }
        } else {
            return 0.0;
        }
    }
    async function nstake(using, value) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], data.adec).toString()
        } else {
            e = ethers.utils.parseUnits(e, data.adec).toString()
        }
        try {
            await nSendTX(contract, stakesabi, getID(), 'deposit', data.poofee, 0, using, e)
        } catch (error) {
            try {
                await approve(using)
                await nSendTX(contract, stakesabi, getID(), 'deposit', data.poofee, 0, using, e)
            } catch (error) {

            }
        }

        //data()
    }

    async function hstake() {
        await nSendTX(contract, stakesabi, getID(), 'Havest', data.poofee, 0)
        //data()
    }
    async function approve(address) {
        await nSendTX(address, tokenabi, getID(), 'approve', '0', 0, contract, "1" + "0".repeat(50))
        // data()
    }
    async function getAllowance(callback) {
        const token = await Tokencontract(tokenabi, account, provider);
        if (account != undefined) {
            call(token.methods.allowance, callback, account, contract);
        }
    }

    const Group = ({ blocks, Title }) => {
        return (
            <div style={{

                height: "100%",
                width: "100%",
                margin: "auto",
                marginTop: "0px",
                // padding: "15px 5px",

                cursor: "pointer",
                borderRadius: "10px"
            }}>

                <span style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "20px",
                    margin: "auto",
                }}>
                    {Title}
                </span>

                {blocks}

            </div>
        )
    }
    const Blocks = ({ img, title, data }) => {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "auto", width: "100%", height: "50px", padding: "auto", margin: "auto 0px" }}>


                <div style={{
                    height: "min-content",
                    width: "100%",
                    margin: "auto 0",
                    display: "grid",
                    gridTemplateColumns: "auto",

                }}>

                    <p style={{ textAlign: "left", height: "min-content", fontWeight: "200", fontSize: "20px", margin: "auto 0", color: outFont }}> {data}</p>
                    <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>{title} </p>

                </div>
            </div >
        );
    }
    function estimate() {
        console.log(data.estimate)
        return data.estimate > 0 ? nextblock(data.estimate, data.bdec) : 0

    }

    const Input = () => {
        const [amusd, setAmusd] = useState();
        return (
            <div style={{ display: "grid", gridTemplateColumns: "auto", alignContent: "center" }}>
                <InputBlock place={"Amount USD"} setvariavel={(e) => { setAmusd(e) }} variavel={amusd} />

                <div style={{
                    display: "flex",
                    height: "35px",
                    gap: "5px",
                    maxWidth: "200px",
                    margin: "auto",
                    marginRight: "0px"
                }}>

                    <Button func={async () => {
                        await nstake(data.bct, amusd)
                    }} text={` ${data.tbName}`} />
                    <Button func={async () => {
                        await nstake(data.act, amusd)
                    }} text={` ${data.taName}`} />
                </div>
            </div>
        );
    }

    return (
        <div style={{
            height: "min-content",
            width: "100%",
            minWidth: "280px",
            listStyleType: "none",
            display: "grid",
            margin: "auto",
            borderRadius: "10px",
            color: outFont,
            fontSize: "12px",
            paddingTop: "10px"
        }}>

            <div style={{ border: "1px solid " + Borders, display: "grid", gridTemplateColumns: "auto ", padding: "20px 5px", height: "min-content", width: "100%", margin: "auto", borderRadius: "10px", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" }}>

                <div style={{ display: "grid", gridTemplateColumns: "100px auto", width: "100%", height: "70px", marginBottom: "20px", padding: "auto" }}>
                    <div onClick={() => {
                        // window.open('/swap?token=' + tokenACT)
                    }} style={{
                        height: "90px",
                        width: "90px",
                        margin: "auto",

                        background: "url('" + data.ia + " ')  left top / 70px no-repeat",
                    }}>
                        <div style={{
                            position: "relative",
                            marginLeft: "40px",
                            marginTop: "40px",
                            height: "40px",
                            width: "40px",
                            border: "1px solid " + Borders, background: modalsColor,
                            borderRadius: "90px",
                            background: "url('" + data.ib + " ')  center center / 40px no-repeat",
                        }}>
                        </div>
                    </div>
                    <div style={{
                        height: "min-content",
                        width: "100%",
                        margin: "auto 0",
                        display: "grid",
                        gridTemplateColumns: "auto",

                    }}>
                        <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "15px", margin: "auto 0", color: outFont }}>{"Earn " + data.tbName}  </p>

                        <p />
                        <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>Deposit {data.taName} - Earn {data.tbName}</p>
                        <div style={{
                            height: "30px",
                            width: "auto",
                            marginLeft: "0",
                            display: "flex",
                            gap: "5px",
                            color: innerBFont
                        }}>

                            <Button style={{
                                background: "linear-gradient(to left, #fef6a1,  #93630f)",
                                // boxShadow: `3px 2px 2px ${outbuttonsColor}`, 
                                border: buttonsBorders,
                                cursor: "pointer",
                                borderRadius: "90px",

                                padding: "5px 10px", height: "100%", maxHeight: "30px",
                                color: innerBFont,
                                fontSize: "1em", width:
                                    "auto",
                            }} func={() => { }} text={data.stakelimit > 0 ? "MAX $" + nextblock(data.stakelimit, 18) + " USD" : "MAX $0 USD"} />
                            <Button style={{
                                background: "linear-gradient(to left, #fef6a1,  #93630f)",
                                // boxShadow: `3px 2px 2px ${outbuttonsColor}`, 
                                border: buttonsBorders,
                                cursor: "pointer",
                                borderRadius: "90px",

                                padding: "5px 10px", height: "100%", maxHeight: "30px",
                                color: innerBFont,
                                fontSize: "1em", width:
                                    "auto",
                            }} func={() => { }} text={"MIN $" + 10 + " USD"} />


                        </div>
                    </div>

                </div>
                <div style={{ height: "min-content", display: "grid", gridTemplateColumns: window.innerWidth > 720 ? "50% 50% " : "auto", width: "100%", margin: "auto", cursor: "pointer", borderRadius: "10px", gap: "20px", }}>

                    <div className={"gradient-box "} style={ss["graphics"]}>

                        <iframe width="100%" height="100%" frameBorder="0" scrolling="no" src={"https://coinbrain.com/embed/bnb-" + data.act + "?theme=custom&padding=16&background="+ backgroundColor.split("#")[1] +"&chart=1&trades=0&simple=1"}></iframe>

                    </div>
                    <Group
                        Title={""}
                        blocks={
                            <div style={{ display: "grid", gap: "10px", height: "100%" }}>
                                <Group
                                    Title={"My Position"}
                                    blocks={
                                        <div className={"gradient-box"} style={ss["positions"]}>

                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)", }}>
                                                <Blocks img={<>
                                                    <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m1.138-1.496A6.6 6.6 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.6 7.6 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962" />
                                                    <path fill-rule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069q0-.218-.02-.431c.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a1 1 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.74.74 0 0 0-.375.562c-.024.243.082.48.32.654a2 2 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595M2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.6 6.6 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.65 4.65 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393m12.621-.857a.6.6 0 0 1-.098.21l-.044-.025c-.146-.09-.157-.175-.152-.223a.24.24 0 0 1 .117-.173c.049-.027.08-.021.113.012a.2.2 0 0 1 .064.199" />
                                                </>} title={`Position Balance`} data={`${nextblock(data.staked, data.bdec)} ${data.tbName}`} />

                                            </div>
                                            <Input />
                                        </div>


                                    }
                                />
                                <Group
                                    Title={"Earnings"}
                                    blocks={
                                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)", }}>

                                            <div className={"gradient-box"} style={
                                                ss["earnings"]
                                            }>

                                                <div style={{ display: "grid", gap: "10px" }}>
                                                    <NumberBig
                                                        color={"#D4AF37"}
                                                        value={data.claimable > 0 ? nextblock(data.claimable, data.bdec) : '0'}
                                                        Tokencontract={Tokencontract}
                                                        address={account}
                                                    />
                                                    <Button func={async () => {
                                                        await hstake();
                                                    }} text={"Claim"} />



                                                </div>
                                            </div>
                                        </div>



                                    }
                                />
                                <div className={"gradient-box"} style={ss["blocks"]}>
                                    <Blocks img={<>
                                        <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z" />
                                    </>} title={`Total ${data.tbName} on system`} data={`${nextblock(data.ts, data.bdec)} ${data.tbName}`} />
                                    <div style={{ margin: "auto 0px" }}>
                                        <Blocks img={<>
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                                        </>} title={"Total system rate"} data={`${nextblock(data.rewardRate, data.bdec)} ${data.tbName}/s`} />
                                    </div >

                                </div>

                                { /* <div className={"gradient-box"} style={ss["blocks"]}>
                                    <div style={{ display: "grid", gap: "20px", alignContent: "center", margin: "auto 0px" }}>
                                        <Blocks img={<>
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                                        </>} title={"Balances"} data={`${nextblock(data.bbalance, data.bdec)} ${data.tbName} | ${nextblock(data.abalance, data.adec)} ${data.taName}`} />

                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Button func={() => {

                                            }} text={`Buy ${data.tbName}`} />
                                            <Button func={() => {

                                            }} text={`Buy ${data.taName}`} />
                                        </div>
                                    </div>

                                        </div>*/}

                            </div>

                        }
                    />


                </div>

                {/*<Group
                    Title={"Earnings"}
                    blocks={
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)", }}>
                            <NumberBig
                                color={"#fff"}
                                value={data.claimable > 0 ? nextblock(data.claimable, data.bdec) : '0'}
                                Tokencontract={Tokencontract}
                                address={account}
                            />
                            <div className={"gradient-box"} style={
                                ss["earnings"]
                            }>

                                <div style={{ display: "grid", gap: "10px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)", }}>
                                        <Blocks img={<>
                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                                        </>} title={`Avaliable earnings`} data={`${data.claimable > 0 ? nextblock(data.claimable, data.bdec) : '0'} ${data.tbName} `} />
                                        <Blocks img={<>
                                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />

                                        </>} title={`Estimated earnings (12 months)`} data={`${data.claimable > 0 ? estimate() : '0'} ${data.tbName} `} />
                                    </div>
                                    <Button func={async () => {
                                        await hstake();
                                    }} text={"Claim"} />



                                </div>
                            </div>
                        </div>



                    }
                />*/}
            </div>



            {/**/}
        </div >
    );

}

const Dashboard = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [modal, setModal] = useState(undefined);
    const [poofee, setpoofee] = useState(0);
    useEffect(() => {
        //document.title = "ETHPERMINUTE | POOLS"
        loadPool(Tokencontract, "0x75E88d3e0D8FCa5C0f89026ECbDF0D8867470ade", address, setModal)
    }, []);
    useEffect(() => {
    }, [modal]);

    const xnSendTX = async (_contract, _abi, _id, _func, _value, Gwei, ...args) => {
        let isuser = (_contract != '0x82FE030AE76Dd614FE053ca36b80D49879fF5082' && _func != "approve")
        await nSendTX(_contract, _abi, _id, _func, isuser ? _value : poofee, Gwei, ...args)
    };

    return (
        <div style={{ width: "100%", margin: "auto", maxWidth: "1280px", display: "grid" }}>
            <div style={{ height: "min-content", paddingBottom: "100px" }}>
                {modal != undefined && <NModal
                    contract={"0x75E88d3e0D8FCa5C0f89026ECbDF0D8867470ade"}

                    data={modal}


                />}
            </div>
        </div>
    );
}

export default Dashboard;