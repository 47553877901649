import React, { useContext } from "react";
import InputBlock from "../components/Launchpad/InputBlock";
import Button from "../components/Launchpad/Button";
import ProjectData from '../config';
import AppContext from "../framework/helpers/AppContext";
import tokenabi from "../abis/bnbabi.json";
import stakesabi from "../abis/stakesn.json";



const Blockss = ({ img, title, data, acolor, bcolor }) => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "auto", width: "100%", height: "50px", padding: "auto", margin: "auto 0px" }}>


            <div style={{
                height: "min-content",
                width: "100%",
                margin: "auto 0",
                display: "grid",
                gridTemplateColumns: "auto",

            }}>

                <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "20px", margin: "auto 0", color: acolor }}> {data}</p>
                <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: bcolor }}>{title} </p>

            </div>
        </div >
    );
}
const Plans = () => {
    const licence = [
        10, 20, 30, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500
    ]
    const stakeAddress = "0x75E88d3e0D8FCa5C0f89026ECbDF0D8867470ade"
    const ETH = "0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378"
    const TOKEN = "0x22b44a9d8d5584A88FBD9F833ffB4e3bb20F303E"
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT,
        theme
    } = useContext(AppContext)
    const {
        Name,
        Logo,
        backgroundColor,
        buttonsColor,
        buttonsBorders,
        inputsColor,
        inputsBorders,
        modalsColor,
        outbuttonsColor,
        Borders,
        outFont,
        innerFont,
        innerBFont,
        Routers
    } = theme
    function getQuery(query) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get(query);
        return foo;
    }

    async function send(using, plan) {
        const ref = getQuery("ref") == null
            ? "0x0000000000000000000000000000000000000000"
            : getQuery("ref")
        try {

            await nSendTX(stakeAddress, stakesabi, getID(), 'getPlan', "0", 0, using, plan, ref)
        } catch (error) {
            try {
                await nSendTX(using, tokenabi, getID(), 'approve', '0', 0, stakeAddress, "1" + "0".repeat(50))
                await nSendTX(stakeAddress, stakesabi, getID(), 'getPlan', "0", 0, using, plan, ref)
            } catch (error) {

            }
        }
    }
    return (<>
        <div style={{
            height: "min-content",
            width: "100%",
            minWidth: "280px",
            listStyleType: "none",
            display: "grid",
            margin: "auto",
            borderRadius: "10px",
            color: outFont,
            fontSize: "12px",
            paddingTop: "10px",
            maxWidth: "1280px"
        }}>

            <div style={{ border: "1px solid " + Borders, background: modalsColor, display: "grid", padding: "20px 5px", height: "min-content", width: "100%", margin: "auto", borderRadius: "10px", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px", gap: "20px" }}>
                {
                    licence.map((e, i) =>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr)", gap: "10px", alignContent: "center", background: "url('" + (window.location.protocol + (window.innerWidth > 720 ? "/borderlong.png" : "/border.png")) + " ') center center / 100% 100%  no-repeat", padding: "20px 25px", borderRadius: "10px" }}>
                            <Blockss img={<>
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                            </>} title={"License Price"} data={e + " USD"} acolor={outFont} bcolor={"#D4AF37"} />
                            <Blockss img={<>
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                            </>} title={"Deposit Limit"} data={parseFloat(e * 10) + " USD"} acolor={outFont} bcolor={"#D4AF37"} />
                            <Blockss img={<>
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                            </>} title={"Reward Limit"} data={parseFloat(parseFloat(e * 10) * 3) + " USD"} bcolor={outFont} acolor={"#D4AF37"} />
                            <Blockss img={<>
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />

                            </>} title={"Buy License"} acolor={outFont} bcolor={"#D4AF37"}
                                data={
                                    <div
                                        style={{ display: "flex", gap: "10px", maxHeight:"30px" }}>
                                        <Button style={{
                                            background: "linear-gradient(to left, #fef6a1,  #93630f)",
                                            // boxShadow: `3px 2px 2px ${outbuttonsColor}`, 
                                            border: buttonsBorders,
                                            cursor: "pointer",
                                            borderRadius: "90px",
                                            minWidth: "100px",
                                            padding: "5px 10px", height: "100%", maxHeight: "30px",
                                            color: innerBFont,
                                            fontSize: "13px", width:
                                                "100%", margin: "auto",
                                        }} func={async () => {
                                            await send(ETH, i)
                                        }} text={"ETH"} />
                                        <Button style={{
                                            background: "linear-gradient(to left, #fef6a1,  #93630f)",
                                            // boxShadow: `3px 2px 2px ${outbuttonsColor}`, 
                                            border: buttonsBorders,
                                            cursor: "pointer",
                                            borderRadius: "90px",
                                            minWidth: "100px",
                                            padding: "5px 10px", height: "100%",maxHeight: "30px",
                                            color: innerBFont,
                                            fontSize: "13px", width:
                                                "100%", margin: "auto",
                                        }} func={async () => {
                                            await send(TOKEN, i)
                                        }} text={"TOKEN"} />
                                    </div>
                                } />

                        </div>
                    )
                }


            </div>


        </div>

    </>)
}
export default Plans