import './App.css';

import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Connection from './hooks/wallet/connection';
import './pages/home.css';
import './pages/farm.css';
import { ethers } from 'ethers';
import cakeabi from "./abis/pankakeRouter.json";
import WButton from './components/wallet/wwallet';
//screens
import Terms from './pages/terms';
import Dashboard from './pages/stake/dash';
import Plans from './pages/plans';
import { useNavigate } from "react-router-dom";
import { Alert, Emit } from './components/Alert/Alert';
import ProjectData from './config';

import ThemeButton from './components/themeButton';
import ThemeHook from './components/themeHook';
import AppContext from './framework/helpers/AppContext';

let projectName = "ETHPERMINUTE"

const Web3 = require('web3-eth');
const w = window.innerWidth;



const Main = ({ changeTheme }) => {
  const {
    Name,
    Logo,
    backgroundColor,
    background,
    buttonsColor,
    buttonsBorders,
    modalsColor,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    topFont,
    innerFont,
    innerBFont,
    menudata,
    Routers,
    logoUrl,
    info
  } = useContext(AppContext).theme
  let sideli = {
    height: "50px",
    width: "100%",
    display: "grid",
    cursor: "pointer",
    paddingLeft: "10px",
    paddingRight: "10px",

  }
  let sidelis = {
    height: "50px",
    width: "100%",
    margin: "auto",
    textAlign: "left",
    display: "grid",
    gridTemplateColumns: "40px auto 25px",
    fontWeight: "bold",
    color: topFont == undefined ? outFont : topFont,
    fontSize: "13px",
    cursor: "pointer"

  }
  let sidelistop = {
    height: "70px",
    width: "100%",
    minWidth: "120px",
    margin: "auto",
    textAlign: "left",
    display: "flex",
    fontWeight: "bold",
    color: topFont == undefined ? outFont : topFont,
    fontSize: "13px",
    cursor: "pointer"

  }
  let logo = Logo
  const navigate = useNavigate();
  const [prices, setprice] = useState(0);
  const [menu, setmenu] = useState(w > 700);
  const [seconds, setSeconds] = useState(0);
  const [menuData, setMenuData] = useState([
    {
      name: "Dashboard",
      onClick: () => { navigate("/") },
      icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
      open: false,
      submenu: [
      ]

    },
    {
      name: "Plans",
      onClick: () => { navigate("/plans") },
      icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
      open: false,
      submenu: [
      ]

    },
    {
      name: "Terms & Privacy",
      onClick: () => { navigate("/terms") },
      icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
      open: false,
      submenu: [
      ]

    },
    {
      name: "Contact",
      onClick: () => { window.open("mailto:contact@ETHPERMINUTE.site?subject=Hello%20ETHPERMINUTE ETHPERMINUTEelopers ...") },
      icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
      open: false,
      submenu: [
      ]

    },

  ]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logoUrl;
    document.title = (Name).toUpperCase() + getPATH()
  }, [window.location.pathname]);

  const getPATH = () => {
    if (window.location.pathname.includes("/")) {
      let f = window.location.pathname.split("/")
      let string = ""
      for (let index = 0; index < f.length; index++) {
        string = string.concat((" " + f[index]).toUpperCase())
      }
      return string
    } else {
      return ""
    }
  }

  const setOpen = (pos) => {
    let _menuData = menuData;
    _menuData[pos].open = !_menuData[pos].open
    setMenuData(_menuData);
  }
  const SubOption = (c) => {
    return (
      <div
        onClick={() => c.obj.onClick()}
        style={{
          height: "min-content",
          width: "100%",
          margin: "auto",
          textAlign: "left",
          color: topFont == undefined ? outFont : topFont,
          padding: "10px",
          paddingLeft: "40px",
          fontSize: "14px",
          cursor: "pointer",
          //  background: modalsColor
        }}>{c.obj.name}
      </div>
    );
  }

  const Option = (props) => {
    const [num, setNum] = useState(0);
    useEffect(() => {
      let interval = setInterval(() => {
        setNum(num + 1)
      }, 100);

      return () => clearInterval(interval);
    }, [num]);
    return (
      <>
        {
          menudata[props.index]?.permit &&
          <>
            <div onClick={() => { props.objm.onClick() }} style={sidelis}>
              <div style={sideli} >
                {props.objm.icon}
              </div>
              <p style={{ margin: "auto", marginLeft: "0px" }}>{props.objm.name}</p>

              {props.objm.submenu.length > 0 && <svg style={{ height: "10px", width: "10px", margin: "auto", rotate: props.objm.open ? "0deg" : "270deg" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>}
            </div>
            {(props.objm.submenu.length > 0 && props.objm.open) && <div style={{}}>
              {props.objm.submenu.map((e, i) => menudata[props.index].subpermit[i]?.permit && <SubOption obj={e} />)}
            </div>}
          </>
        }

      </>
    )
  }


  const SubOptionTop = (c) => {
    return (
      <div className="itemtohover" onClick={() => c.obj.onClick()} style={{
        height: "35px",
        width: "200px",
        margin: "auto",
        textAlign: "left",
        display: "grid",
        gridTemplateColumns: "25px auto",
        fontWeight: "bold",
        color: topFont == undefined ? outFont : topFont,
        fontSize: "13px",
        cursor: "pointer",
        background: Borders,
        borderRadius: "10px"
      }}>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          cursor: "pointer",
          paddingLeft: "10px",
          paddingRight: "10px",
          margin: "auto"
        }} >
          {c.info != undefined && c.info.icon}
        </div>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          cursor: "pointer",
          paddingLeft: "10px",
          paddingRight: "10px",
          margin: "auto",

        }}>
          <p style={{ fontWeight: "none", fontSize: "12px" }}>{c.obj.name}</p>

        </div>
      </div>
    );
  }

  const OptionTop = (props) => {
    const [num, setNum] = useState(0);
    useEffect(() => {
      let interval = setInterval(() => {
        setNum(num + 1)
      }, 1000);

      return () => clearInterval(interval);
    }, [num]);
    // if(menudata[props.index].permit == undefined)
    return (
      <>
        {
          menudata[props.index]?.permit &&
          <>
            <div className='menuitem' onClick={() => { props.objm.onClick() }} style={sidelistop}>
              <p style={{ margin: "auto", fontWeight: "none", }}>{props.objm.name}</p>
              {props.objm.submenu.length > 0 && <svg style={{ height: "10px", width: "10px", margin: "auto", rotate: true ? "0deg" : "270deg" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>}
              {(props.objm.submenu.length > 0) && <div className='menuitem onItemHover' style={{

                gap: "10px", padding: "5px", background: buttonsColor, position: "absolute", top: "70px", maxWidth: "600px",
                border: "1px solid" + Borders, gridTemplateColumns: "auto ", borderRadius: "10px"
              }}>
                {props.objm.submenu.map((e, i) => menudata[props.index].subpermit[i]?.permit && <SubOptionTop obj={e} info={props.objm.submenuinfo[i]} />)}
              </div>}
            </div>
          </>}
      </>
    )
  }
  function nextblock(accountBalancemctTB, d) {
    return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
  }

  async function price() {
    const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org"));
    const cake = await new web3.Contract(cakeabi, '0x10ed43c718714eb63d5aa57b78b54704e256024e');
    const p0 = await cake.methods.getAmountsOut("1" + "0".repeat(18), ['0x116526135380E28836C6080f1997645d5A807FAE', "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"]).call()
    const p1 = await cake.methods.getAmountsOut(p0[1], ["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call()
    setprice(p1[1])
  }

  function menustyle(bool) {
    return <div style={{
      height: "70px",
      width: "100%",
      margin: "auto",
      overflow: "hidden",
      display: "grid",
      gridTemplateColumns: "150px auto min-content",

    }}>
      <div style={{
        height: "50px",
        width: "100%",
        overflow: "hidden",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        cursor: "pointer",
        margin: "auto"
      }}>
        <svg onClick={() => { setmenu(!menu) }} style={{
          height: "30px",
          width: "30px",
          margin: "auto",
          fill: topFont == undefined ? outFont : topFont
        }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM cSawQi"><path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" /></svg>
        <img src={logoUrl} style={{
          height: "30px",
          width: "30px",
          margin: "auto",
          marginLeft:"0px"}} />
      </div>
      <div></div>
      <div style={{
        display: "grid", gridTemplateColumns: "auto auto", height: "min-content", width: "100%", margin: "auto",
        borderRadius: "10px"
      }}>
        <WButton />
        <ThemeButton changeTheme={changeTheme} />
      </div>
    </div>
  }

  const Sidemenu = () => {
    return (
      <div style={{
        height: window.innerHeight - 100,
        width: "260px",
        color: topFont == undefined ? outFont : topFont,
        position: w < 720 ? "absolute" : "absolute",
        top: "0px",
        zIndex: "10000",
        borderRight: "1px solid" + Borders,
        background: "#000000",
        bottom: "0",
        display: "grid",
      }}>
        <div style={{
          height: window.innerHeight - 100,
          overflowY: "auto",
          overflowX: "hidden",
        }}>
          <div style={{
            height: "70px",
            width: "100%",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: w > 720 ? "auto" : " auto 70px",
            cursor: "pointer"
          }}>
            {logo}

            {w < 720 && <svg onClick={() => { setmenu(!menu) }} style={{
              height: "30px",
              width: "30px",
              margin: "auto",
              fill: topFont == undefined ? outFont : topFont
            }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM cSawQi"><path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" /></svg>}

          </div>

          {menuData.map((e, i) => <Option objm={e} index={i} />)}
        </div>
        <div style={{
          height: "100px",
          width: "100%",
          display: "grid",
          gridTemplateColumns: "auto",
          background: "#000000",
          color: topFont == undefined ? outFont : topFont,
          padding: "0 1vh",
          borderTop: "1px solid " + Borders
        }}>
          {false && <div onClick={() => { }} style={{
            display: "grid", gridTemplateColumns: "50px auto", height: "50px", width: "100%", margin: "auto",
            background: "#00000050", borderRadius: "10px"
          }}>
            {logo}
            <p style={{ fontSize: "15px", padding: "0 1vh", textAlign: "right", height: "min-content", margin: "auto 0", fontWeight: "bold" }}>${(nextblock(prices, 18))} USD</p>
          </div>}
          <div style={{
            display: "grid",
            gridTemplateColumns: "auto auto"
          }}>
            <svg onClick={() => { window.open(info.telegram) }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM jgodFW" style={{ cursor: 'pointer', margin: "auto", height: "30px", width: "30px", fill: topFont == undefined ? outFont : topFont }}><path d="M12 3C7.02985 3 3 7.02985 3 11.9904C3 16.9606 7.02985 21 12 21C16.9701 21 21 16.9606 21 11.9904C21 7.02985 16.9701 3 12 3ZM16.4328 8.65139C16.3753 9.48614 14.8305 15.7228 14.8305 15.7228C14.8305 15.7228 14.7345 16.0874 14.3987 16.097C14.2836 16.097 14.1301 16.0874 13.9574 15.9243C13.6023 15.6269 12.7964 15.0512 12.0384 14.5235C12.0096 14.5522 11.9808 14.581 11.9424 14.6098C11.7697 14.7633 11.5107 14.984 11.2324 15.2527C11.1269 15.3486 11.0117 15.4542 10.8966 15.5693L10.887 15.5789C10.8198 15.6461 10.7623 15.694 10.7143 15.7324C10.3401 16.0394 10.3017 15.7804 10.3017 15.6461L10.5032 13.4488V13.4296L10.5128 13.4104C10.5224 13.3817 10.5416 13.3721 10.5416 13.3721C10.5416 13.3721 14.4659 9.87953 14.5714 9.50533C14.581 9.48614 14.5522 9.46695 14.5043 9.48614C14.2452 9.57249 9.72601 12.4318 9.22708 12.7484C9.19829 12.7676 9.11194 12.758 9.11194 12.758L6.91471 12.0384C6.91471 12.0384 6.65565 11.9328 6.742 11.693C6.76119 11.645 6.78998 11.597 6.89552 11.5299C7.38486 11.1844 15.8955 8.12367 15.8955 8.12367C15.8955 8.12367 16.1354 8.04691 16.2793 8.09488C16.3465 8.12367 16.3849 8.15245 16.4232 8.2484C16.4328 8.28678 16.4424 8.36354 16.4424 8.44989C16.4424 8.49787 16.4328 8.55544 16.4328 8.65139Z" /></svg>
            <svg onClick={() => { window.open(info.twitter) }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM jgodFW" style={{ cursor: 'pointer', margin: "auto", height: "30px", width: "30px", fill: topFont == undefined ? outFont : topFont }}><path d="M8.65854 19C15.4488 19 19.1659 13.2338 19.1659 8.23717C19.1659 8.07728 19.1659 7.91739 19.1561 7.7475C19.878 7.21785 20.5024 6.5483 21 5.78881C20.3366 6.08861 19.6244 6.28847 18.8732 6.38841C19.6341 5.91872 20.2195 5.17921 20.5024 4.28981C19.7902 4.72951 19 5.03931 18.1512 5.20919C17.478 4.45969 16.522 4 15.4585 4C13.4195 4 11.761 5.69887 11.761 7.78747C11.761 8.08728 11.8 8.36709 11.8585 8.6469C8.79512 8.497 6.07317 6.97801 4.24878 4.68954C3.92683 5.25916 3.75122 5.90873 3.75122 6.59827C3.75122 7.90739 4.40488 9.06662 5.39024 9.74617C4.78537 9.72618 4.21951 9.5563 3.72195 9.27648C3.72195 9.28648 3.72195 9.30646 3.72195 9.32645C3.72195 11.1552 5 12.6942 6.67805 13.034C6.36585 13.1239 6.0439 13.1639 5.70244 13.1639C5.46829 13.1639 5.23415 13.1439 5.00976 13.0939C5.47805 14.6029 6.8439 15.6922 8.46341 15.7222C7.19512 16.7415 5.60488 17.3411 3.87805 17.3411C3.58537 17.3411 3.28293 17.3311 3 17.2911C4.62927 18.3704 6.57073 19 8.65854 19Z" /></svg>
          </div>
        </div>
      </div>
    )
  }
  const Topmenu = () => {
    return (
      <div style={{
        height: "70px",
        width: "100%",
        color: topFont == undefined ? outFont : topFont,
        position: "absolute",
        borderBottom: "1px solid" + Borders,
        top: "0px",
        zIndex: "10000",
        bottom: "0",
        display: "grid",
        gridTemplateColumns: "auto",
        background: buttonsColor
      }}>
        <div style={{
          height: "70px",
          width: "100%",
          maxWidth: "1280px",
          margin: "auto",
          color: topFont == undefined ? outFont : topFont,
          zIndex: "10000",
          borderBottom: "1px solid" + Borders,
          bottom: "0",
          display: "grid",
          gridTemplateColumns: "auto min-content min-content",
          background: "transparent"
        }}>
          <div style={{
            height: "70px",
            maxWidth: "200px",
            display: "grid",

            gridTemplateColumns: "auto",
            cursor: "pointer"
          }}>
            {logo}
          </div>


          <div
            style={{
              height: "min-content",
              width: "100%",
              color: topFont == undefined ? outFont : topFont,
              //background: "#fff",
              bottom: "0",
              display: "flex",
              justifyContent: "flex-end",
              gap: "5px"


            }}>
            {menuData.map((e, i) => <OptionTop objm={e} index={i} />)}
          </div>

          <div style={{
            display: "grid", gridTemplateColumns: "auto auto", height: "min-content", width: "100%", margin: "auto",

          }}>
            <WButton />
            <ThemeButton changeTheme={changeTheme} />
          </div>
        </div>

      </div>

    )
  }

  const router = <Routes>
    <>
      <Route path={'/'} element={<Dashboard />} />
      <Route path={'/plans'} element={<Plans />} />
      <Route path={'/terms'} element={<Terms />} />

    </>
  </Routes>

  return (
    <div className="App" style={{ overflow: "auto", background: background }}>

      <div style={{
        width: "100%",
        margin: "auto",
        height: "100vh"
      }}>
        {w < 720 ? (<div style={{
          width: "100%",
          display: "grid",
          maxWidth: "1920px",
        }}>
          {menu && <Sidemenu />}
          {menustyle(false)}
          <div className='b' style={{ height: "90vh" }}>
            {router}
          </div>
        </div>) : (<div style={{
          display: "grid",
          gridTemplateRows: (w >= 1024 ? "70px auto" : "100px auto"),
        }}>
          <Topmenu />
          <div className='b' style={{ height: "90vh", display: "block", margin: "auto", marginTop: "0px", maxWidth: "1920px", }}>
            <div style={{ height: "70px" }}></div>
            {router}
          </div>
        </div>)}
      </div>
      <Alert />
    </div>


  );
}

const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") != null ? localStorage.getItem("theme") : 0)//0 == night
  function changeTheme() {
    localStorage.setItem("theme", theme == 0 ? 1 : 0)
    setTheme(localStorage.getItem("theme"))
    // alert(localStorage.getItem("theme"))
  }
  return (
    <>

      <Connection theme={theme} content={
        <Main changeTheme={changeTheme} />

      } />
    </>
  )
}

export default App;